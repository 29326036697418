import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './Title.module.css';

export default function Title({ className = '', children }) {
  if (!children) {
    return null;
  }

  return <h2 className={clsx(styles.title, className)}>{children}</h2>;
}

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};
