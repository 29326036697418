import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useMirror from '../../hooks/useMirror';
import Button from '../Button/Button';
import Link from '../Link';
import * as styles from './Navigation.module.css';

export default function Navigation({ settings, navigation }) {
  const headerRef = useRef();
  const [showNavigation, setShowNavigation] = useState(false);
  const [showExpanded, setShowExpanded] = useState({});
  const headerBounds = useMirror(headerRef);

  useEffect(() => {
    function handleResize() {
      setShowNavigation(false);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { headerNavigationItems = [], callToActionItems = [] } = navigation;

  return (
    <div id="header">
      <header ref={headerRef} className={styles.header}>
        <div className={styles.logoContainer}>
          {settings.logos?.map((logo) => (
            <Link href={logo.url} key={logo.url}>
              <img className={styles.logo} src={logo.image} alt={logo.url} />
            </Link>
          ))}
        </div>

        <nav
          className={clsx(styles.navigation, {
            [styles.open]: showNavigation,
          })}
        >
          <ul className={styles.list}>
            {headerNavigationItems.map((navigationItem, i) => (
              <li
                key={navigationItem.label}
                className={clsx(styles.listItem, {
                  [styles.open]: showExpanded[i],
                })}
              >
                <span className={styles.link}>
                  <Link href={navigationItem.url} className={styles.linkLabel}>
                    {navigationItem.label}
                  </Link>
                  {navigationItem.children ? (
                    <button
                      type="button"
                      className={styles.expandButton}
                      onClick={() =>
                        setShowExpanded((prev) => ({ ...prev, [i]: !prev[i] }))
                      }
                    >
                      {showExpanded[i] ? <>&#9650;</> : <>&#9660;</>}
                    </button>
                  ) : null}
                </span>
                {navigationItem.children ? (
                  <ul className={styles.childList}>
                    {navigationItem.children.map((child) => (
                      <li key={child.label} className={styles.link}>
                        <Link
                          href={child.url}
                          className={clsx(styles.linkLabel, styles.childLink)}
                        >
                          {child.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </nav>

        {callToActionItems.length > 0 ? (
          <div className={styles.callToActionContainer}>
            {callToActionItems.map((callToAction) => (
              <Button
                key={callToAction.label}
                href={callToAction.url}
                theme={callToAction.theme}
              >
                {callToAction.label}
              </Button>
            ))}
          </div>
        ) : null}

        <button
          type="button"
          className={clsx(styles.toggleNavigationButton, {
            [styles.open]: showNavigation,
          })}
          onClick={() => setShowNavigation((prev) => !prev)}
        >
          <span className={styles.hamburger} />
        </button>
      </header>
      <div style={{ height: headerBounds.height }} />
    </div>
  );
}

Navigation.propTypes = {
  settings: PropTypes.shape().isRequired,
  navigation: PropTypes.shape().isRequired,
};
