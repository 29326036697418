// extracted by mini-css-extract-plugin
export const header = "Navigation-module--header--2WEDc";
export const navigation = "Navigation-module--navigation--xUJt5";
export const hamburger = "Navigation-module--hamburger--1sCKI";
export const toggleNavigationButton = "Navigation-module--toggleNavigationButton--2xQQ0";
export const open = "Navigation-module--open--1YnLH";
export const list = "Navigation-module--list--3rrwK";
export const link = "Navigation-module--link--GerdN";
export const linkLabel = "Navigation-module--linkLabel--3CvPk";
export const expandButton = "Navigation-module--expandButton--2NVpF";
export const childList = "Navigation-module--childList--3zpGp";
export const childLink = "Navigation-module--childLink--3FzEd";
export const listItem = "Navigation-module--listItem--3XWxZ";
export const logoContainer = "Navigation-module--logoContainer--2YuK_";
export const logo = "Navigation-module--logo--1HCxg";
export const callToActionContainer = "Navigation-module--callToActionContainer--3rYF7";