import { Link as ReactRouterLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export default function Link({ href, children, ...rest }) {
  // Internal link
  if (href?.startsWith('/')) {
    return (
      <ReactRouterLink to={href} {...rest}>
        {children}
      </ReactRouterLink>
    );
  }

  // External link, we don't want to take them entirely away from the site so open in a new tab
  const props = !href?.includes('qwikbetz.com')
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <a href={href} {...props} {...rest}>
      {children}
    </a>
  );
}

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
};
