import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Link from '../Link';
import * as styles from './Button.module.css';

export default function Button({
  type = 'button',
  children,
  theme = 'secondary',
  className = '',
  href,
  ...rest
}) {
  if (href) {
    return (
      <Link
        href={href}
        className={clsx(styles.button, styles[theme], className)}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(styles.button, styles[theme], className)}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  href: PropTypes.string,
};
