import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout/Layout';

export default function PageLayout({ children, data = {} }) {
  const siteSettings = useStaticQuery(graphql`
    {
      settings: markdownRemark(frontmatter: { type: { eq: "settings" } }) {
        frontmatter {
          title
          logos {
            image
            url
          }
        }
      }
      theme: markdownRemark(frontmatter: { type: { eq: "theme" } }) {
        frontmatter {
          primary
          secondary
          primaryButtonFontColor
          secondaryButtonFontColor
          navigationBackgroundColor
          navigationFontColor
          footerBackgroundColor
          footerFontColor
          listItemBackgroundColor
          listItemFontColor
        }
      }
      navigation: markdownRemark(frontmatter: { type: { eq: "navigation" } }) {
        frontmatter {
          headerNavigationItems {
            label
            url
            children {
              label
              url
            }
          }
          footerNavigationItems {
            label
            url
            children {
              label
              url
            }
          }
          callToActionItems {
            label
            url
            theme
          }
        }
      }
    }
  `);

  return (
    <Layout
      settings={siteSettings.settings.frontmatter}
      theme={siteSettings.theme.frontmatter}
      navigation={siteSettings.navigation.frontmatter}
    >
      <Helmet title={data.title}>
        <meta name="description" content={data.description} />
        <link
          rel="icon"
          type="image/png"
          href={siteSettings.settings.favicon}
        />
      </Helmet>
      {children}
    </Layout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape(),
};
