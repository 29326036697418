import { ApolloProvider } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import PageContext from '../../contexts/PageContext';
import { AppStateProvider } from '../../hooks/useAppState';
import apolloClient from '../../utils/apolloClient';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import * as styles from './Layout.module.css';

export default function Layout({
  settings = {},
  theme = {},
  navigation = [],
  children,
}) {
  return (
    <ApolloProvider client={apolloClient}>
      <PageContext.Provider value={{ settings, theme, navigation }}>
        <AppStateProvider>
          <main
            style={{
              '--primary': theme.primary,
              '--secondary': theme.secondary,
              '--primaryButtonFontColor': theme.primaryButtonFontColor,
              '--secondaryButtonFontColor': theme.secondaryButtonFontColor,
              '--navigationBackgroundColor': theme.navigationBackgroundColor,
              '--navigationFontColor': theme.navigationFontColor,
              '--footerBackgroundColor': theme.footerBackgroundColor,
              '--footerFontColor': theme.footerFontColor,
              '--listItemBackgroundColor': theme.listItemBackgroundColor,
              '--listItemFontColor': theme.listItemFontColor,
            }}
            className={styles.main}
          >
            <Helmet titleTemplate={`%s · ${settings.title}`}>
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1"
              />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
                rel="stylesheet"
              />
            </Helmet>
            <Navigation settings={settings} navigation={navigation} />
            <div className={styles.content}>{children}</div>
            <Footer navigation={navigation} />
          </main>
        </AppStateProvider>
      </PageContext.Provider>
    </ApolloProvider>
  );
}

Layout.propTypes = {
  settings: PropTypes.shape(),
  theme: PropTypes.shape(),
  navigation: PropTypes.shape(),
  children: PropTypes.node.isRequired,
};
