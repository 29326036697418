/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Link from '../Link';
import * as styles from './Footer.module.css';

export default function Footer({ navigation }) {
  const { footerNavigationItems = [] } = navigation;

  return (
    <footer className={styles.container}>
      <Container>
        <nav>
          <ul className={clsx(styles.list, styles.mainList)}>
            {footerNavigationItems.map((navigationItem, navIndex) => (
              <li key={navIndex} className={styles.mainListItem}>
                {navigationItem.label ? (
                  <Link href={navigationItem.url} className={styles.link}>
                    {navigationItem.label}
                  </Link>
                ) : null}
                {navigationItem.children ? (
                  <ul className={styles.list}>
                    {navigationItem.children.map((child, childIndex) => (
                      <li key={childIndex}>
                        <Link href={child.url} className={styles.link}>
                          {child.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </nav>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  navigation: PropTypes.shape().isRequired,
};
