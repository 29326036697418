import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './Container.module.css';

export default function Container({ children, className = '', ...rest }) {
  return (
    <div className={clsx(styles.container, className)} {...rest}>
      <div className={styles.innerContainer}>{children}</div>
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
