import { gql, useQuery, useSubscription } from '@apollo/client';
import { useState } from 'react';

export default function useMeetings(variables) {
  const [meetings, setMeetings] = useState(null);

  useQuery(
    gql`
      query meetingsDated($firstRaceFrom: Time, $date: Time, $to: Time) {
        meetingsDated(firstRaceFrom: $firstRaceFrom, date: $date, to: $to) {
          id
          type
          track {
            id
            name
          }
          races {
            id
            number
            startTime
            status
            competitors {
              tabNo
              name
              trainer
              jockey
              barrier
              scratched
              tabNo
              silksURL(size: 32)
              prices(sources: ["${process.env.GATSBY_PRICE_CODE}"]) {
                price
                type
                tabNo
              }
            }
          }
        }
      }
    `,
    {
      variables,
      onCompleted: (data) => {
        setMeetings(data?.meetingsDated);
      },
    }
  );

  useSubscription(
    gql`
      subscription raceUpdated {
        raceUpdated {
          id
          startTime
          status
        }
      }
    `,
    {
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data && meetings) {
          const raceUpdate = subscriptionData.data.raceUpdated;
          setMeetings((prevMeetings) =>
            prevMeetings.map((meeting) => {
              const races = meeting.races.map((race) => {
                if (race.id === raceUpdate.id) {
                  return {
                    ...race,
                    startTime: raceUpdate.startTime,
                    status: raceUpdate.status,
                  };
                }
                return race;
              });
              return { ...meeting, races };
            })
          );
        }
      },
    }
  );

  return meetings;
}
