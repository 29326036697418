import { useEffect, useState, useRef, useCallback } from 'react';

export default function useMirror(ref, props = []) {
  const resizingRef = useRef(false);
  const [mirrorBounds, setMirrorBounds] = useState({});

  const recalculate = useCallback(() => {
    if (ref.current) {
      setMirrorBounds(ref.current.getBoundingClientRect());
      resizingRef.current = false;
    }
  }, [ref, resizingRef]);

  useEffect(() => {
    function handleResize() {
      if (ref.current && !resizingRef.current) {
        resizingRef.current = true;
        setTimeout(recalculate);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [recalculate, ref]);

  useEffect(() => {
    recalculate();
  }, [...props, recalculate]);

  return mirrorBounds;
}
